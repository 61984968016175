.days_off_form {
  .day_off_reset_button.button {
    position: relative;
    margin: 8px;
    background-image: none;
  }

  .days_off_info_text {
    background-color: $color-grey-2;
    margin-top: -15px;
    text-align: center;
    font-size: 13px;
    margin-left: 35px;
    padding: 14px 20px;
  }

  .approval_needed.compact .onoffholder label {
    width: 95px !important;
    padding-right: 5px;
  }

  .tooltip {
    vertical-align: middle;
    margin-top: 0px;
    float: none;
  }

  .reset_all_user_day_off_setting {
    background-color: rgba(246, 141, 67, 0.2);
    margin-top: 20px;
    font-size: 11px;
    color: $color-primary-2;
    padding: 10px 0px;
    margin-bottom: -25px;

    .reset_day_off_all_users {
      float: none;
      width: auto;

      .tooltip {
        margin: -2px 8px 0 0;
      }

      &.compact {
        .onoffholder {
          label {
            width: auto !important;
          }
        }
      }
    }

    .vacation_type_users {
      margin-left: 55px;
      padding-top: 15px;
    }
  }

  .div.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }
}

.days_off_info_wrapper {
  .div.compact {
    width: 100%;

    .onoffholder {
      width: 100%;

      label {
        width: calc(100% - 160px) !important;
      }
    }
  }
}
