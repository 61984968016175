.ahoy-banner {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 12px;

    > p:last-child {
      margin-bottom: 0;
    }

    &-neutral {
        background-color: $ahoy-color-neutral-light;
        color: $ahoy-color-teal-darkest;
        border: 1px solid $ahoy-color-neutral;
    }

    &-ruby {
        background-color: $ahoy-color-ruby-lightest;
        color: $ahoy-color-ruby-darkest;
        border: 1px solid $ahoy-color-ruby;
    }

    &-gold {
        background-color: $ahoy-color-gold-lightest;
        color: $ahoy-color-gold-darkest;
        border: 1px solid $ahoy-color-gold;
    }

    &-mint {
        background-color: $ahoy-color-mint-lightest;
        color: $ahoy-color-mint-darkest;
        border: 1px solid $ahoy-color-mint;
    }
}
