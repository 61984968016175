.ahoy-button-destructive {
  background-color: $ahoy-color-ruby;
  border: 1px solid $ahoy-color-ruby-dark;
  color: $ahoy-color-neutral-lightest;

  &:focus-visible {
    border-color: $ahoy-color-ruby-darkest;
    box-shadow: 0 0 0 1px $ahoy-color-ruby-darkest;
  }

  &:hover,
  &:active {
    background-color: $ahoy-color-ruby-dark;
    border-color: $ahoy-color-ruby-darkest;
    box-shadow: none;
  }

  &:disabled {
    opacity: 0.24;
  }
}
