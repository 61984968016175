.colorpicker_wrapper {
  position: relative;
}

.colorpicker_wrapper .colorpicker_selected_color {
  position: absolute;
  height: 12px;
  width: 12px;
  right: 15px;
  top: 6px;
}

.colorpicker_wrapper .colorpicker_colors .colorpicker_color {
  width: 25px;
  height: 25px;
  display: inline-block;
  margin: 3px;
  border: 1px solid transparent;
}

.colorpicker_wrapper .colorpicker_colors {
  position: absolute;
  left: 100px;
  top: 25px;
  padding-top: 5px;
  background-color: $color-white;
  width: 169px;
  border: 1px solid #ccc;
  padding-left: 8px;
  z-index: 1000;
}

.colorpicker_wrapper .colorpicker_input:focus {
  outline: 0;
}

.open_colorpicker.colorpicker_colors {
  display: block;
}
.colorpicker_colors {
  display: none;
}

.colorpicker_wrapper
  .colorpicker_colors
  .colorpicker_color.selected_color:before {
  content: "\ea10";
  font-family: "icomoon";
  position: absolute;
  width: 27px;
  height: 27px;
  text-align: center;
  vertical-align: middle;
  line-height: 27px;
}
.colorpicker_wrapper .colorpicker_colors .colorpicker_color.selected_color {
  box-shadow: 0 0 5px 0 rgba($color-black, 0.3);
}

.invisble_input_color_select {
  width: 0px;
  height: 0px;
  opacity: 0;
  display: inline-block;
  position: absolute;
}
