$cdn-url: "https://focus.teamleader.eu";
@import 'utils/variables';
@import 'modules/subscription-preview';
@import 'ahoy/button-base';
@import 'ahoy/button-primary';
@import 'ahoy/banner';

.popup-without-iframe {
  background: $ahoy-color-neutral-lightest;
  border-radius: 4px;

  h1 {
    border-radius: 4px 4px 0 0;
  }

  &:not(.popup-overhaul) {
    @import 'popup';

    position: relative;
    text-align: left;
    overflow: hidden;
    min-height: 320px;
    clear: both;
    margin: 0;
    padding: 0 0 60px 0;
    color: $color-primary-2;
    width: 100%;
    background: linear-gradient(to top, $color-grey-2 60px, $color-white 60px);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;

    &.no-footer {
      background: #fff;
    }

    h1 {
      padding-right: 60px;
      border-top: none;
    }

    #buttonspace,
    .button,
    a.delete_button,
    .loading-cloak,
    .popupform #topright {
      position: absolute;
    }

    a.buttonactivity,
    a.delete_button.activity {
      &:after {
        left: 50%;
        top: 50%;
      }
    }

    .onoffholder {
      padding: 0;
      overflow: visible;

      label {
        font-style: normal;
      }
    }

    .cke_skin_kama {
      float: left;
      position: relative;
    }

    .cke {
      float: left;
      margin-bottom: 35px;
      position: relative;
      padding-bottom: 28px;
    }

    .ck-editor {
      float: left;
      width: calc(100% - 100px);
      margin-bottom: 35px;
      position: relative;
      height: auto;
    }

    .ck-editor__editable {
      min-height: 200px;
  
      h1, h2, h3, h4, p {
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        clear: none;
        color: $color-primary-2 !important;
        float: none; 
        font-family: $ahoy-font-family-inter !important;
        height: auto;
        line-height: 1.5;
        margin: 0;
        padding: 8px 0px;
        width: auto;
      }
  
      h1 {
        font-size: 20px !important;
        font-weight: 700 !important;
      }
  
      h1::before {
        content: none;
      }
  
      h1::after {
        content: none;
      }
  
      h2{
        font-size: 17px !important;
        font-weight: 700 !important;
      }
  
      h3 {
        font-size: 14px !important;
        font-weight: 700 !important;
      }
  
      h4 {
        font-size: 13px !important;
        font-weight: 700 !important;
      }
  
      p {
        font-size: 12px !important;
      }
  
      ol, ul {
        font-family: $ahoy-font-family-inter !important;
        font-size: 12px !important;
        color: $color-primary-2 !important;
        padding-inline-start: revert;
        padding-left: revert;
        margin-block-start: 12px;
        margin-block-end: 12px;
      }

      figure {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .ck-source-editing-area {
      height: 202px;

      textarea {
        width: 100%;
        height: 100%;
        overflow: visible;
      }
    }

    .ck-source-editing-area::after {
      content: '';
    }

    input.file {
      background: none;
      border: none;
      height: auto;
      padding: 0;
    }

    .compact.toggle-left {
      position: absolute;
    }

    #invoice_reminder_form {
      .integration {
        background-image: none;
      }
    }

    #i_accept_holder {
      position: absolute;
    }

    .notice {
      &.badnotice,
      &.goodnotice {
        box-shadow: none;

        &:before {
          display: none;
        }
      }
    }

    .tagholder {
      height: auto;
    }

    .popup-fetch-error {
      padding: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 270px;
      flex-direction: column;
    }

    #external_cost_form #leftbottom {
      position: absolute;
    }

    .no-link-warning {
      color: $ahoy-color-gold-dark;
      font-size: 12px;
      padding-left: 100px;

      a {
        color: $ahoy-color-gold-dark;
        text-decoration: underline;
      }

      a:hover {
        color: $ahoy-color-gold-darkest;
      }
    }

    #custom_field_form h2,
    #custom_options_editor_form h2 {
      margin: 16px 0 16px 0;
    }

    #custom_target_form .posrel {
      clear: none;
    }

    #email_template_form .template_variables:last-of-type {
      margin-bottom: 12px;
    }
  }

  &.popup-overhaul {
    min-height: 250px;
    display: flex;
    flex-direction: column;

    h1 {
      text-align: left;
      font-size: 16px;
      font-family: $ahoy-font-family-inter;
      font-weight: 500;
      background-color: $ahoy-color-neutral-light;
      color: $ahoy-color-teal-darkest;
      height: 58px;
      line-height: 58px;
      padding: 0 54px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-shadow: inset 0px -1px 0px $ahoy-color-neutral;
    }

    & h1::before {
      position: absolute;
      left: 0;
      margin: 10px;
      content: '';
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzgyODI4QyIgb3BhY2l0eT0iMC44NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTguNSA2QzkuMzI4NDMgNiAxMCA1LjMyODQzIDEwIDQuNUMxMCAzLjY3MTU3IDkuMzI4NDMgMyA4LjUgM0M3LjY3MTU3IDMgNyAzLjY3MTU3IDcgNC41QzcgNS4zMjg0MyA3LjY3MTU3IDYgOC41IDZaIiAvPgo8cGF0aCBkPSJNOC41IDIxQzkuMzI4NDMgMjEgMTAgMjAuMzI4NCAxMCAxOS41QzEwIDE4LjY3MTYgOS4zMjg0MyAxOCA4LjUgMThDNy42NzE1NyAxOCA3IDE4LjY3MTYgNyAxOS41QzcgMjAuMzI4NCA3LjY3MTU3IDIxIDguNSAyMVoiIC8+CjxwYXRoIGQ9Ik0xMCAxMkMxMCAxMi44Mjg0IDkuMzI4NDMgMTMuNSA4LjUgMTMuNUM3LjY3MTU3IDEzLjUgNyAxMi44Mjg0IDcgMTJDNyAxMS4xNzE2IDcuNjcxNTcgMTAuNSA4LjUgMTAuNUM5LjMyODQzIDEwLjUgMTAgMTEuMTcxNiAxMCAxMloiIC8+CjxwYXRoIGQ9Ik0xNS41IDEzLjVDMTYuMzI4NCAxMy41IDE3IDEyLjgyODQgMTcgMTJDMTcgMTEuMTcxNiAxNi4zMjg0IDEwLjUgMTUuNSAxMC41QzE0LjY3MTYgMTAuNSAxNCAxMS4xNzE2IDE0IDEyQzE0IDEyLjgyODQgMTQuNjcxNiAxMy41IDE1LjUgMTMuNVoiIC8+CjxwYXRoIGQ9Ik0xNyA0LjVDMTcgNS4zMjg0MyAxNi4zMjg0IDYgMTUuNSA2QzE0LjY3MTYgNiAxNCA1LjMyODQzIDE0IDQuNUMxNCAzLjY3MTU3IDE0LjY3MTYgMyAxNS41IDNDMTYuMzI4NCAzIDE3IDMuNjcxNTcgMTcgNC41WiIgLz4KPHBhdGggZD0iTTE1LjUgMjFDMTYuMzI4NCAyMSAxNyAyMC4zMjg0IDE3IDE5LjVDMTcgMTguNjcxNiAxNi4zMjg0IDE4IDE1LjUgMThDMTQuNjcxNiAxOCAxNCAxOC42NzE2IDE0IDE5LjVDMTQgMjAuMzI4NCAxNC42NzE2IDIxIDE1LjUgMjFaIiAvPgo8L3N2Zz4=');
      background-repeat: no-repeat;
      background-position: center;
      width: 36px;
      height: 36px;
      line-height: 36px;
    }

    .button {
      @extend .ahoy-button-base;
      @extend .ahoy-button-primary;
      @extend .ahoy-button-medium;

      text-decoration: none;
    }

    .popup-autosuggestions {
      @extend .ahoy-banner;
      @extend .ahoy-banner-gold;

      margin: 0 18px 18px 18px;
    }

    .popup-actions {
      border-top: 1px $ahoy-color-neutral solid;
      padding: 18px;
      display: flex;
      justify-content: flex-end;

      & > *:not(:first-child) {
        margin-left: 18px;
      }
    }

    .ajax_cc_suggestions {
      border-radius: 4px;
      left: 132px;
      top: 27px;
      width: 166px;

      .sg_con,
      .sg_com,
      .sg_pro {
        width: 139px;
      }
    }

    a.goback {
      background-image: url('#{$cdn-url}/img/goback.png?v=3');
      background-size: contain;
      height: 12px;
      width: 12px;
      margin: 7px 0px 0px 2px;
    }

    input + .icon-input {
      right: 0;
    }

    #autosuggestions {
      display: none;
    }

    #invoicing_preview .notice {
      float: none;
      font-size: 14px;
      line-height: 30px;
      padding: 15px 15px 15px 65px;
      width: auto;
    }
    
    .ck-editor {
      width: calc(100% - 100px);
    }    

    .ck-editor__editable {
      min-height: 200px;
  
      h1, h2, h3, h4, p {
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        clear: none;
        color: $color-primary-2 !important;
        float: none; 
        font-family: $ahoy-font-family-inter !important;
        height: auto;
        line-height: 1.5;
        margin: 0;
        padding: 8px 0px;
        width: auto;
      }
  
      h1 {
        font-size: 20px !important;
        font-weight: 700 !important;
      }
  
      h1::before {
        content: none;
      }
  
      h1::after {
        content: none;
      }
  
      h2{
        font-size: 17px !important;
        font-weight: 700 !important;
      }
  
      h3 {
        font-size: 14px !important;
        font-weight: 700 !important;
      }
  
      h4 {
        font-size: 13px !important;
        font-weight: 700 !important;
      }
  
      p {
        font-size: 12px !important;
      }
  
      ol, ul {
        font-family: $ahoy-font-family-inter !important;
        font-size: 12px !important;
        color: $color-primary-2 !important;
        padding-inline-start: revert;
        padding-left: revert;
        margin-block-start: 12px;
        margin-block-end: 12px;
      }

      figure {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  #deactivate_employee_form select {
    float: left;
    margin-left: 7px;
    margin-bottom: 5px;
    width: 190px;
  }
  #deactivate_employee_form span {
    float: left;
    margin-left: 7px;
    margin-bottom: 5px;
  }

  #trendstop_lookup_form .result {
    margin-top: 12px;
  }
}

#popup-close-button > button,
#popupmover {
  z-index: 1;
}

#employee_form.restyled {
  display: flex;
  flex-direction: column;

  .div.compact {
    padding: 36px;
  }

  .onoffholder {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    max-width: 426px;
    padding: 6px;
    overflow: visible;

    &:hover {
      background-color: #f7f7fa;
    }

    label {
      flex: 1;
    }

    .onoff {
      width: 30px;
      height: 18px;
      background-color: none;
      transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
      border-radius: 12px;
      border-width: 1px;
      border-style: solid;
      display: flex;
      align-items: center;
      padding: 1px;
      box-sizing: border-box;
      cursor: pointer;

      &::after {
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;
        transition-duration: 0.35s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-property: border-color, transform;
        background-color: #fff;
      }

      > div {
        display: none;
      }

      &.off {
        background-color: #f7f7fa;
        border-color: #c0c0c4;

        &::after {
          border-color: #c0c0c4;
        }
      }

      &.on {
        background-color: #00b2b2;
        border-color: #008a8c;

        &::after {
          transform: translateX(calc(100% - 2px));
          border-color: #008a8c;
        }
      }
    }
  }

  .badnotice {
    &::before {
      content: '';
      position: static;
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10Zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm0-1.975a1.5 1.5 0 1 1 .001-3 1.5 1.5 0 0 1-.001 3ZM12 6a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1Z' fill='%23992600'/%3E%3C/svg%3E");
    }

    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0 36px;
    box-shadow: none;
    border: 1px solid #ff7040;
    border-radius: 4px;
    color: #992600;
    background: #fff0ec;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
    width: auto;
  }
}

#tools_user.restyled {
  display: flex;
  flex-direction: column;

  *:not([class^='ahoy']):not([class^='icon']):not([class*=' icon-']):not(#thebutton):not(#resetPasswordButton):not(
      [class*='-control'] *
    ) {
    font: 14px/18px 'Inter', trebuchet ms, Verdana, 'Arial', sans-serif !important;
  }

  > br {
    display: none;
  }

  &::after {
    content: '';
    display: block;
    order: 3;
    border-bottom: 1px solid #e4e4e6;
  }

  .select:not(.div) {
    border: 1px solid #c0c0c4;
    border-radius: 4px;
    box-sizing: border-box;
    width: auto;

    background-image: none;
    padding-right: 32px !important;
    text-overflow: ellipsis;
    max-width: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.46452 6.08711L7.00006 9.62265L10.5356 6.08711C10.9261 5.69659 10.9261 5.06342 10.5356 4.6729C10.1451 4.28237 9.5119 4.28237 9.12138 4.6729L7.00006 6.79422L4.87874 4.6729C4.48821 4.28237 3.85505 4.28237 3.46452 4.6729C3.074 5.06342 3.074 5.69659 3.46452 6.08711Z' fill='%2382828C'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 9px center;
  }

  .div {
    float: none;
    margin: 0;
  }

  > .div:first-of-type {
    order: 1;
    width: 100%;
    columns: 2;
    column-gap: 0;
    padding: 36px 18px;
    box-sizing: border-box;

    .div {
      display: flex;
      flex-flow: column;
      gap: 3px;
      width: auto;
      padding: 0 18px;
      -webkit-column-break-inside: avoid;
      break-inside: avoid;

      & + div {
        margin-top: 12px;
      }
    }

    label {
      color: #1a1c20;
      width: auto;
      height: 18px;
    }

    input,
    select,
    .input-unit {
      border: 1px solid #c0c0c4;
      border-radius: 4px;
      box-sizing: border-box;
      width: auto;
      height: 36px;

      &:disabled {
        border: none;
        background: #e4e4e6;
      }
    }

    #currency-select input {
      height: auto;
    }

    input,
    select {
      padding: 0 6px;
    }

    .input-unit {
      display: flex;
      align-items: stretch;
      padding: 0;

      input {
        flex: 1;
        height: 100%;
        border: none;
        text-align: right;
      }

      &:focus-within {
        outline: none !important;
      }

      span {
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 1px;
        padding-right: 6px;
        box-sizing: border-box;
      }
    }

    #resetPasswordButton {
      margin-top: 10px;
      line-height: 1;
    }
  }

  #toggledivs {
    order: 2;
    display: flex;
    flex-direction: row;
    padding: 0 18px 36px;
    margin: 0;
    width: auto;

    .div {
      display: flex;
      flex-direction: row;
      padding: 0;
      width: 100%;
      height: 24px;
      width: auto;
    }

    .div.select {
      label {
        flex: 2;
      }

      select {
        flex: 3;
        height: 24px;
      }
    }

    .div.compact,
    .div.select,
    .div.opa {
      padding: 0 6px;
      align-items: center;

      span {
        color: #1a1c20;
        opacity: 1;
      }

      &:hover {
        background-color: #f7f7fa;
      }
    }

    .tooltip {
      background: none;
      border-radius: 0;
      cursor: pointer;
      float: none;
      height: 14px;
      width: 14px;
      margin-left: 3px;

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.84' d='M7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1ZM7 11C7.55228 11 8 10.5523 8 10L8 7C8 6.44772 7.55228 6 7 6C6.44772 6 6 6.44772 6 7L6 10C6 10.5523 6.44772 11 7 11ZM7 5C7.55228 5 8 4.55229 8 4C8 3.44772 7.55228 3 7 3C6.44772 3 6 3.44772 6 4C6 4.55229 6.44772 5 7 5Z' fill='%231A1C20'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
      }

      i {
        border-radius: 4px;
      }
    }

    .col {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 3px;
      width: auto;
      float: none;
      padding: 0 18px;

      > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 24px;
        background: none;
        border: none;
        padding: 0;
        width: auto;

        &[id^='extra_options_'] {
          display: flex;
          height: auto;
          flex-flow: column;
          align-items: stretch;
          gap: 3px;
          padding-left: 24px;
        }

        .onoffholder {
          display: flex;
          gap: 6px;
          align-items: center;
          width: 100%;
          padding: 0;
          overflow: visible;
        }

        label {
          flex: 1;
          display: flex;
          align-items: center;
          float: none;
          width: auto !important;
          padding: 0;
          cursor: default;
        }

        .onoff {
          width: 30px;
          height: 18px;
          background-color: none;
          transition: background-color 0.35s cubic-bezier(0.4, 0, 0.2, 1);
          border-radius: 12px;
          border-width: 1px;
          border-style: solid;
          display: flex;
          align-items: center;
          padding: 1px;
          box-sizing: border-box;
          cursor: pointer;

          &::after {
            content: '';
            display: block;
            height: 12px;
            width: 12px;
            border-width: 1px;
            border-style: solid;
            border-radius: 50%;
            transition-duration: 0.35s;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-property: border-color, transform;
            background-color: #fff;
          }

          > div {
            display: none;
          }

          &.off {
            background-color: #f7f7fa;
            border-color: #c0c0c4;

            &::after {
              border-color: #c0c0c4;
            }
          }

          &.on {
            background-color: #00b2b2;
            border-color: #008a8c;

            &::after {
              transform: translateX(calc(100% - 2px));
              border-color: #008a8c;
            }
          }
        }
      }
    }
  }

  #thebutton {
    order: 4;
    width: fit-content;
    margin: 18px 18px 18px auto;
  }
}

.popup-without-iframe:has(#remove_recurring_object) {
  min-height: unset;
}

#project_client_external_access_form,
#project_thirdparty_access_form,
#limit_timetracking_switch_form {
  p {
    margin-top: 12px;
  }
}
