.ahoy-button-base {
  box-sizing: border-box;
  border-radius: 4px;

  width: auto;

  position: relative;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-family: $ahoy-font-family-inter;
  font-weight: 500;
  letter-spacing: 0;

  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  transition-property: background-color, border-color, color, opacity;

  &:hover {
    text-decoration: none;
  }

  svg {
    opacity: 0.84 !important;
  }

  &.icon-only {
    padding: 0;
  }

  &.icon-left {
    background-position: 12px 50%;
    padding-left: 36px;
  }

  &.icon-right {
    background-position: calc(100% - 12px) 50%;
    padding-right: 36px;
  }

  &:enabled,
  &:not(.ahoy-button-disabled):not(.buttondisabled) {
    cursor: pointer;

    &:active {
      box-shadow: inset 0 2px 3px color(var(--color-neutral-darkest) a(12%));
    }
  }
}

.ahoy-button-disabled,
.ahoy-button-base.buttondisabled {
  cursor: default;
  opacity: 0.24;
}

.ahoy-button-tiny {
  font-size: 12px;
  height: 24px;
  line-height: 18px;
  min-width: 30px;
  padding: 0 6px;
}

.ahoy-button-small {
  font-size: 14px;
  height: 30px;
  line-height: 28px;
  min-width: 30px;
  padding: 0 12px;
}

.ahoy-button-medium {
  font-size: 14px;
  height: 36px;
  line-height: 34px;
  min-width: 36px;
  padding: 0 12px;
}

.ahoy-button-large {
  font-size: 16px;
  height: 48px;
  line-height: 46px;
  min-width: 48px;
  padding: 0 18px;
}

.ahoy-button-full-width {
  width: 100%;
}
