.document-layout-download {
  &__actions {
    margin: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__action {
    margin-bottom: 12px;
  }
}
