.message_wrapper.attachments {
  border: 1px solid $color-grey-5;
  float: left;
  position: relative;
  width: 520px;

  #attached_files_input {
    bottom: 0px;
  }

  #extra_attachments {
    width: 320px;
    .attachme {
      margin: 0 10px 5px 0;
      .attach {
        max-width: 250px;
      }
    }
  }
  .verybig {
    width: 516px;
    border: none;
    max-height: 220px;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  #attached_files_input {
    width: 500px;
    min-height: 35px;
    position: relative;
    margin-bottom: 5px;
  }
  #extra_attach_select,
  #extra_attach_input_clicker {
    position: absolute;
    bottom: 5px;
    right: 0px;
  }
}

#ckeditor_holder {
  #attachment_button {
    position: absolute;
    bottom: 0px;
    right: 0px;
  }
  #extra_attach_input_clicker {
    white-space: nowrap;
  }
}
