.document-layout-shortcodes {
  &__info-wrapper {
    display: flex;
    margin: 24px 0;
  }

  &__description,
  &__support {
    flex: 1;
    padding: 9px 18px;
  }

  &__description {
    border-right: 1px solid $ahoy-color-neutral;
    flex: 1.5;
  }

  &__support {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-description {
      margin-top: 0;
      font-family: $ahoy-font-family-inter;
      font-weight: 700;
    }
  }

  &__create-shortcode {
    margin: 24px 18px;
  }

  &__shortcode-actions {
    align-self: flex-end;
  }

  &__header,
  &__cell {
    box-shadow: 0 1px 0 0 #e3e3e6;
    padding: 9px 0 !important;
  }

  &__header,
  &__shortcode,
  &__shortcode-description {
    padding: 0 18px !important;
  }

  &__shortcode-description {
    font-size: 13px !important;
    flex: 1;
  }

  &__shortcode-description-icon {
    display: inline-flex;
    height: 14px;
    width: 14px;
    margin-right: 6px;
  }

  &__cell {
    display: flex;
    align-items: center;
    > * {
      flex: 1;
    }
  }

  &__header {
    margin: 0 !important;
    border-top: 1px #e3e3e6;
    color: $ahoy-color-neutral-darkest !important;
    background-color: $ahoy-color-neutral-light;
    float: none !important;
    clear: none !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  &__shortcode {
    font-family: $ahoy-font-family-inter !important;
    color: $ahoy-color-teal-darkest;
    font-weight: 400 !important;
    font-size: 11px !important;
    flex: 1.3;
    display: flex;
    align-items: center;
    gap: 6px;

    &-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 18px;

      > * {
        padding: 0 !important;
      }
    }
  }
}
