.input-unit {
  width: 173px;
  border: 1px solid #bababa;
  font-size: 12px;
  height: 22px;
  line-height: 22px;
  padding: 0 0 0 4px;
  position: relative;
  display: flex;

  /*
    workaround for edge/ie11, the border will not be on the full div
    but on the input
	*/
  &:focus-within {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: -2px;

    & input:focus {
      outline: none;
    }
  }

  & input {
    border: none;
    font-size: 12px;
    flex-grow: 1;
    background-color: inherit;

    &:disabled + span {
      color: grey;
    }
  }

  & span {
    margin-right: 4px;
  }
}
