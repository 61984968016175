.demo_item_list {
  display: table;
}
.demo_item_list .row {
  display: table-row;
}
.demo_item_list .row .type {
  display: table-cell;
  font-size: 13px;
  color: rgba(43, 59, 76, 0.7);
}
.demo_item_list .row .amount {
  display: table-cell;
  padding: 2px 5px;
  font-size: 13px;
  color: rgba(43, 59, 76, 0.7);
}
.demo_item_list .row .amount .inner_amount {
  display: block;
  padding: 1px 5px;
  background-color: $color-primary-1;
  text-align: center;
  color: $color-white;
}

.delete_demo_data .button.never_delete_demodata {
  background-image: none;
  padding-right: 12px;
}

.demodata_select {
  width: 100%;
  margin-left: 55px;
  margin-top: 20px;
}
.demodata_select .option_wrapper {
  width: calc(50% - 28px);
  float: left;
  color: rgba(43, 59, 76, 0.7);
  cursor: pointer;
}
.demodata_select .option_wrapper .inp-radio {
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
}
.demodata_select .option_wrapper .option_text {
  display: inline-block;
  padding-left: 20px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 16px;
}
.demodata_select .option_wrapper .option_description {
  font-size: 13px;
  padding-left: 5px;
  padding-bottom: 20px;
}
.demodata_select .option_wrapper.option_checked .option_description,
.demodata_select .option_wrapper.option_checked .demo_item_list {
  opacity: 1;
}
.demodata_select .option_wrapper .option_description,
.demodata_select .option_wrapper .demo_item_list {
  opacity: 0.5;
}

.demo_buttons.show_keep .delete_button {
  display: none;
}
.demo_buttons.show_keep .confirm_remove {
  display: none;
}
.demo_buttons .never_delete_demodata {
  display: none;
}
.demo_buttons.show_keep .never_delete_demodata {
  display: block;
}
