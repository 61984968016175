#subscription_preview {
  height: 120px;
  margin: 30px 0 0 35px;
  text-align: center;

  // TODO: remove once timeline is 100% rolled out
  img {
    margin-left: 20px;
  }

  ol {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    padding: 0 48px;
  }

  // the li borders are the lines between the circles, everything else is positioned relative to them
  li {
    list-style: none;
    border: 1px solid $ahoy-color-neutral-dark;
    flex: 1;
    position: relative;
  }
  li:last-child {
    flex: 0;
    width: 0;
  }

  // circles
  span {
    position: absolute;
    top: -6px;
    left: -6px;
    width: 12px;
    height: 12px;
    background: $ahoy-color-neutral-dark;
    border-radius: 50%;
  }
  li:first-child span {
    background: $ahoy-color-mint;
    &:after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 6px;
      height: 6px;
      background: white;
      border-radius: 50%;
    }
  }
  // if the end of the subscription is visible in the preview, the last circle turns into a vertical bar
  li.last_period:not(:first-child) span {
    top: -6px;
    left: -1px;
    width: 2px;
    height: 12px;
    border-radius: 1px;
  }

  div {
    position: absolute;
    left: 0;
    text-align: left;
    margin-left: -6px;
    width: 100px;
  }
  li:nth-child(even) div {
    bottom: 18px;
  }
  li:nth-child(odd) div {
    top: 18px;
  }
  ol.length-2 li:nth-child(n + 2) div,
  ol.length-3 li:nth-child(n + 2) div,
  ol.length-4 li:nth-child(n + 3) div,
  ol.length-5 li:nth-child(n + 4) div {
    left: auto;
    right: 100%;
    text-align: right;
    margin-right: -6px;
  }
  // adjust alignment when the last circle is replaced with the vertical bar
  li.last_period:not(:first-child) div {
    margin-right: -1px !important;
  }
  // if there is only one item, center it
  li:only-child div {
    left: -100px;
    width: 200px;
    text-align: center;
    margin: 0;
  }

  strong,
  time {
    font-family: $ahoy-font-family-inter;
    font-size: $font-size-med;
    font-weight: 400;
  }

  strong {
    display: block;
    margin-bottom: 3px;
    font-weight: normal;
  }

  time {
    color: $color-gray;
  }

  li:first-child strong,
  li:first-child time {
    font-family: $ahoy-font-family-inter;
    font-weight: 700;
  }
}
