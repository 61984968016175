.document-layout-custom-css {
  &__info-wrapper {
    display: flex;
    margin: 24px 0;
  }

  &__description,
  &__support {
    flex: 1;
    padding: 9px 18px !important;
  }

  &__description {
    border-right: 1px solid $ahoy-color-neutral;
    flex: 1.5;
  }

  &__support {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 0;

    &-description {
      margin-top: 0;
      font-family: $ahoy-font-family-inter;
      font-weight: 700;
      padding-right: 18px;
    }
  }

  &__editor {
    box-sizing: border-box;
    padding: 9px;
    width: 100% !important;
    height: 100%;
    resize: none;
    border: 1px solid $ahoy-color-neutral;
    margin-bottom: 24px;
  }

  &__form {
    margin: 0 18px !important;
    height: 400px;
  }

  &__save {
    position: absolute !important;
    right: 10px;
    bottom: 10px;
    display: inline-block !important;
  }

  &__dummy {
    height: 24px;
  }
}
