.ahoy-button-primary {
  background-color: $color-primary-1;
  border: 1px solid $color-primary-darkest;
  color: $color-white !important;

  &:hover,
  &:active {
    background-color: $color-primary-darker;
    border: 1px solid $color-primary-darkest;
  }

  &:focus-visible {
    background-color: $color-primary-1;
    border: 1px solid $color-primary-darkest;
    box-shadow: 0 0 0 1px $color-primary-darkest;
  }
}
