#autosuggestions,
.resource_unavailable {
  background-color: #ffbfc2;
  background-image: url("#{$cdn-url}/images/alert.png");
  background-repeat: no-repeat;
  background-position: 12px 6px;
  color: $color-black;
  display: none;
  font-size: 12px;
  height: 34px;
  line-height: 34px;
  margin: 0 0 0 0;
  overflow: hidden;
  padding: 0 15px 0 44px;
  transition: height 0.2s ease-out;
  width: 100%;
}

#autosuggestions {
  p {
    margin: 0;
  }
}

.resource_unavailable {
  .resource_unavailable_text {
    float: left;
    padding-right: 5px;
  }

  .resource_unavailable_timeslots {
    display: table;
    float: left;
  }

  .resource_unavailable_timeslot {
    display: table-row;
  }

  .resource_unavailable_timeslot_time {
    display: table-cell;

    &.resource_unavailable_timeslot_seperator {
      padding: 0px 2px;
    }
  }

  &.display_resource_unavailable {
    display: block;
    height: auto;
    margin-top: 3px;
  }
}
